/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";

import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import PageHelmet from "../../components/Helmet/Helmet";
import { Row, Col } from "react-bootstrap";
import "./Contact.css";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import EmailIcon from "../../Icons/EmailIcon";
import CallIcon from "../../Icons/CallIcon";
import WhatsAppIcon from "../../Icons/WhatsApp";
import LocationIcon from "../../Icons/LocationIcon";
import ChatIcon from "../../Icons/ChatIcon";
import SuccessIcon from "../../Icons/SuccessIcon";

export default function Contact() {
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  useEffect(() => {
    const scriptURL =
      "https://script.google.com/macros/s/AKfycbwgtvqjCnqSN8dk7roKQva1-Q6FtRGLu77Pf5AlwOmLSzuultWUEfzkilxXCSD7jQzE/exec";

    const form = document.forms["contactForm"];

    const handleSubmit = async (e) => {
      e.preventDefault();

      try {
        const response = await fetch(scriptURL, {
          method: "POST",
          body: new FormData(form),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log("Form submitted successfully:", data);
        setIsModalOpen(true);        

        // Clear the form fields after successful submission
        setName('');
        setNumber('');
        setEmail('');
        setSubject('');
        setMessage('');

      } catch (error) {
        console.error("Error!", error.message);
      }
    };

    form.addEventListener("submit", handleSubmit);

    // Cleanup: Remove the event listener when the component is unmounted
    return () => {
      form.removeEventListener("submit", handleSubmit);
    };
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
  };


  return (
    <>
      <PageHelmet
        PageTitle="Aftab Khan - Contact Me"
        CanonicalLink="https://aftabkhan.net/contact"
        description="Connect with Aftab Khan, a Front-End Developer seeking challenging opportunities. Reach out for collaboration, project discussions, or to hire a proficient developer. Contact: aftabkhan.india@gmail.com | (+91) 7013981136."
        keywords="Aftab Khan Contact, Collaboration Opportunities, Project Discussions, Hire Front-End Developer, Web Development Inquiries."
      />
      <section className="app-content-area" id="contact-page">
        <Modal show={isModalOpen} onHide={closeModal}>
          <Modal.Body>
            <SuccessIcon />
            <h2>Success!</h2>
            <p>Your information has been received.</p>
            <p>
              I appreciate your contact and <br />
              will get back to you as soon as possible.
            </p>
            <Button onClick={closeModal}>Okay!</Button>
          </Modal.Body>
        </Modal>
        <div className="container">
          <Row>
            <Col lg={{ span: 5 }}>
              <h2 className="page-heading animate__animated animate__fadeInDown animate__slow">
                What's Next?
              </h2>
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: 5 }} className="mx-auto">
              <div className="chat-icon text-center">
                <div className="animate__animated animate__swing animate__infinite animate__slow">
                  <ChatIcon />
                </div>

                <h2 className="mb-3 animate__animated animate__fadeInUp animate__slow">
                  How can I help you out?
                </h2>
                <p className="animate__animated animate__fadeIn animate__slow animate__delay-1s">
                  Hello! Thank you for reaching out. I'm thrilled to hear from
                  you and are here to assist with any questions or inquiries you
                  may have.
                </p>
                <div className="d-grid contact-details animate__animated animate__fadeIn animate__slow animate__delay-2s">
                  <div className="d-grid text-center info-details">
                    <div className="info-icon">
                      <LocationIcon />
                    </div>
                    <div className="info-text">Hyderabad, India</div>
                  </div>
                  <div className="d-grid text-center info-details">
                    <div className="info-icon">
                      <EmailIcon />
                    </div>
                    <div className="info-text">
                      <a
                        href="mailto:hello@aftabkhan.net"
                        data-email="hello@aftabkhan.net"
                        className="email"
                      ></a>
                    </div>
                  </div>
                  <div className="d-grid text-center info-details">
                    <div className="info-icon">
                      <CallIcon />
                    </div>
                    <div className="info-text">
                      <a
                        href="tel:+917013981136"
                        data-tel="(+91) 70 13 981 136"
                        className="mobo"
                      ></a>
                    </div>
                  </div>
                  <div className="d-grid text-center info-details">
                    <div className="info-icon">
                      <WhatsAppIcon />
                    </div>
                    <div className="info-text">
                      <a
                        href="https://wa.me/+918977384390/?text=Hello%2C%20I%20have%20an%20inquiry%20for%20you%21"
                        data-tel="(+91) 89 77 384 390"
                        className="mobo"
                        rel="noreferrer"
                        target="_blank"
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              lg={{ span: 6 }}
              className="mb-lg-0 mb-3 mt-lg-0 mt-3 offset-lg-1 animate__animated animate__bounceInUp animate__slow"
            >
              <div className="card-background contact-form">
                <div className="hover-state-indicator"></div>
                <div className="card-wrapper solid custom">
                  <h2 className="mb-4">
                    <span>Let's meet on</span> 🍵
                  </h2>
                  <form name="contactForm">
                    <div className="row mb-3">
                      <div className="col-sm-6 mg-lg-0 mb-3">
                        <div className="form-wrap">
                          <label className="form-label" htmlFor="name">
                            Name *
                          </label>
                          <input
                            className="form-input"
                            type="text"
                            id="name"
                            name="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-wrap position-relative">
                          <label className="form-label" htmlFor="number">
                            Phone
                          </label>
                          <input
                            type="tel"
                            name="Number"
                            value={number}
                            className="hidden-field"
                            required
                          />
                          <PhoneInput
                            className="number form-input"
                            country="in"
                            value={number}
                            onChange={(Number) => setNumber(Number)}
                            rules={{ required: true }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-12">
                        <div className="form-wrap">
                          <label className="form-label" htmlFor="email">
                            Email *
                          </label>
                          <input
                            className="form-input"
                            id="email"
                            type="email"
                            name="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-12">
                        <div className="form-wrap">
                          <label className="form-label" htmlFor="subject">
                            Subject *
                          </label>
                          <input
                            className="form-input"
                            id="subject"
                            type="text"
                            name="Subject"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-12">
                        <div className="form-wrap">
                          <label className="form-label" htmlFor="message">
                            Your Message *
                          </label>
                          <textarea
                            className="form-input"
                            id="message"
                            name="Message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-end">
                      <div className="form-button text-center mt-md-0 mt-3">
                        <button
                          className="btn-effect btn-1 w-inline-block"
                          style={{ width: "180px" }}
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      {/* Schema Markup for Contact Me Page */}
      <script type="application/ld+json">
        {`{
          "@context": "http://schema.org",
          "@type": "ContactPage",
          "name": "Aftab Khan - Contact Me",
          "description": "Connect with Aftab Khan, a Front-End Developer seeking challenging opportunities. Reach out for collaboration, project discussions, or to hire a proficient developer.",
          "url": "https://aftabkhan.net/contact",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://aftabkhan.net/contact"
          }
        }`}
      </script>
    </>
  );
};