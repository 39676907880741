export default function EmailIcon() {
  return (
    <svg
      viewBox="0 0 32 32"
      id="OBJECT"
      xmlns="http://www.w3.org/2000/svg"
      fill="#007dc6"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth={0} />
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g id="SVGRepo_iconCarrier">
        <defs>
          <style
            dangerouslySetInnerHTML={{ __html: ".cls-1{fill:#79b9e7;}" }}
          />
        </defs>
        <title />
        <rect
          className="cls-1"
          height={22}
          rx={3}
          ry={3}
          width={30}
          x={1}
          y={5}
        />
        <path
          className="cls-1"
          d="M18.12,12.71a3,3,0,0,0-4.24,0L1.3,25.29A3,3,0,0,0,4,27H28a3,3,0,0,0,2.7-1.71Z"
        />
        <path d="M28,5H4A3,3,0,0,0,1.3,6.71L13.88,19.29a3,3,0,0,0,4.24,0L30.7,6.71A3,3,0,0,0,28,5Z" />
      </g>
    </svg>
  );
}

