/* eslint-disable react/destructuring-assignment */
import React from "react";
import { string } from "prop-types";

const AKLogo = (props) => (
  <svg
    className={props.className}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="75px"
    viewBox="300 200 400 500"
    xmlSpace="preserve"
  >
    <path
      fill="#64ffda"
      opacity={1.0}
      stroke="none"
      d="
M590.163635,366.148682 
	C606.957336,351.727295 625.985291,344.002777 647.877686,343.715271 
	C649.369019,343.695679 650.905334,343.711548 652.347046,344.037140 
	C655.103760,344.659729 657.535828,345.851440 657.177795,349.311127 
	C656.829590,352.675751 654.752014,354.167511 651.436279,354.325348 
	C646.119141,354.578400 640.799255,354.859161 635.496826,355.316376 
	C626.365784,356.103760 618.000366,359.334442 610.145264,363.878967 
	C592.827393,373.897919 565.896973,403.427887 556.939270,422.214996 
	C561.583252,423.291931 566.197876,422.493683 570.746460,422.657593 
	C588.516663,423.298035 602.851440,430.352356 613.372192,444.909698 
	C621.070801,455.561981 623.460327,467.955231 625.266174,480.515686 
	C627.535217,496.298767 627.607910,512.368591 631.332642,527.960999 
	C632.063538,531.020386 633.056213,534.064453 634.326355,536.939697 
	C639.863159,549.473267 649.536926,553.265442 662.130615,547.760925 
	C669.768250,544.422607 675.806030,538.966248 680.953003,532.486145 
	C681.987671,531.183533 682.877014,529.741150 684.044800,528.574890 
	C685.392700,527.228760 686.804993,525.106750 689.063232,526.677307 
	C691.114441,528.103882 690.343201,530.295837 689.545288,532.145447 
	C685.427368,541.690857 678.790894,549.200012 669.435913,553.528992 
	C649.011292,562.980408 628.484436,563.904480 609.022888,551.077393 
	C595.933228,542.450012 589.820496,528.908203 585.765503,514.307983 
	C582.055664,500.950500 582.104980,486.953430 578.806213,473.519257 
	C576.493042,464.099121 573.797241,454.848816 568.294739,446.699646 
	C564.980774,441.791779 560.868591,437.705078 555.489990,435.072571 
	C549.652405,432.215393 548.640869,432.513245 544.689453,437.726562 
	C530.100708,456.974579 515.659790,476.337402 500.857422,495.419891 
	C487.690216,512.394531 474.548431,529.472107 457.661896,543.069092 
	C426.777740,567.936890 376.349121,565.761414 347.631134,538.391235 
	C320.114716,512.166321 318.491730,460.592834 362.443359,437.174805 
	C388.172729,423.465759 415.202271,422.259216 443.026825,428.977570 
	C451.251343,430.963409 459.080048,434.203003 467.298340,437.116882 
	C468.361053,433.538605 467.859100,430.386383 467.808685,427.293060 
	C467.585449,413.601196 466.205139,400.038513 462.773102,386.746368 
	C457.414429,365.992279 444.174255,355.096527 422.821808,354.064636 
	C406.108551,353.256927 389.914154,355.504486 374.787140,363.171112 
	C364.719055,368.273773 357.288330,375.245117 357.052582,387.598816 
	C356.989594,390.898926 355.187775,393.409668 351.376251,393.181671 
	C347.555511,392.953064 346.462921,390.411224 346.496918,386.965546 
	C346.620819,374.399078 353.390198,365.715637 363.104675,358.923035 
	C373.425293,351.706604 385.239014,348.282257 397.506683,346.291077 
	C415.529022,343.365814 433.618744,342.413940 451.635315,346.048218 
	C484.457947,352.669189 502.946167,373.656067 510.240326,405.497345 
	C513.528076,419.849548 514.452576,434.444427 514.155090,449.136719 
	C514.085815,452.559204 514.144836,455.984283 515.225220,459.736359 
	C520.883606,452.417877 526.552795,445.107697 532.198364,437.779297 
	C547.648376,417.724091 563.060486,397.639587 578.554810,377.618774 
	C581.825500,373.392639 585.723938,369.733917 590.163635,366.148682 
M430.759857,547.321716 
	C444.325073,541.901855 455.735687,533.435913 465.549591,522.712891 
	C467.137299,520.978027 468.393768,519.258545 468.418335,516.609070 
	C468.617462,495.123749 468.895416,473.642578 468.109558,452.158936 
	C467.981049,448.645386 466.760162,446.666504 463.524933,445.398895 
	C452.647705,441.137054 441.643433,437.186798 430.029724,435.685699 
	C406.408356,432.632629 390.581329,436.449768 379.692169,462.760345 
	C371.225616,483.217407 371.464478,504.423523 379.422760,525.194702 
	C384.337402,538.021851 392.845886,547.434204 406.977081,550.239502 
	C414.875305,551.807312 422.530548,550.111633 430.759857,547.321716 
z"
    />
  </svg>
);

AKLogo.propTypes = {
  className: string,
};
AKLogo.defaultProps = {
  className: "",
};
export default AKLogo;
