export default function CallIcon() {
  return (
    <svg
      viewBox="0 0 1024 1024"
      className="icon"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
      stroke="#000000"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth={0} />
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g id="SVGRepo_iconCarrier">
        <path
          d="M256 853.333333V170.666667c0-46.933333 38.4-85.333333 85.333333-85.333334h341.333334c46.933333 0 85.333333 38.4 85.333333 85.333334v682.666666c0 46.933333-38.4 85.333333-85.333333 85.333334H341.333333c-46.933333 0-85.333333-38.4-85.333333-85.333334z"
          fill="#565758"
        />
        <path
          d="M682.666667 149.333333H341.333333c-12.8 0-21.333333 8.533333-21.333333 21.333334v618.666666c0 12.8 8.533333 21.333333 21.333333 21.333334h341.333334c12.8 0 21.333333-8.533333 21.333333-21.333334V170.666667c0-12.8-8.533333-21.333333-21.333333-21.333334z"
          fill="#67efe1"
        />
        <path d="M448 853.333333h128v42.666667h-128z" fill="#ffffff" />
      </g>
    </svg>
  );
}
