import React from "react";
import { Helmet } from "react-helmet";

export default function PageHelmet(props) {
  const { PageTitle, CanonicalLink, description, keywords} = props;

  return (
    <Helmet>
      <title>{PageTitle} </title>
      <link rel="canonical" href={CanonicalLink}></link>
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
    </Helmet>
  );
}
