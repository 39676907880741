/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Switch from "../ThemeSwitch/Switch";
import "./Header.css";
import SocialBar from "../SocialBar/SocialBar";
import AKLogo from "../../imgs/SVGs/ak-logo";

export default function Header(props) {
  const location = useLocation();
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const closeNav = () => {
    setIsNavOpen(false);
  };

  return (
    <>
      <header className={`app-header ${isNavOpen ? "menu-visible" : ""}`}>
        {/* Logo */}
        <div className="header-logo position-relative animate__animated  animate__fadeInDown animate__slow">
          <Link to="/">
            <AKLogo className="logo" />
          </Link>
          <div className="bg-blob"></div>
        </div>
        <div className="d-flex align-items-center gap-2">
          {props.mobileView ? (
            <>
              <div className="desktop-nav">
                <ol>
                  <li className="animate__animated animate__fadeInDown animate__fast">
                    <Link
                      to="/about"
                      className={`${
                        location.pathname === "/about" ? "active" : ""
                      } ${isNavOpen ? "is-opened" : ""}`}
                    >
                      <span className="menu-item">About</span>
                    </Link>
                  </li>
                  <li className="animate__animated animate__fadeInDown animate__slow">
                    <Link
                      to="/experience"
                      onClick={closeNav}
                      className={`${
                        location.pathname === "/experience" ? "active" : ""
                      } ${isNavOpen ? "is-opened" : ""}`}
                    >
                      <span className="menu-item">Experience</span>
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to="/work"
                      onClick={closeNav}
                      className={`${
                        location.pathname === "/work" ? "active" : ""
                      } ${isNavOpen ? "is-opened" : ""}
            `}
                    >
                      <span className="menu-item">Work</span>
                    </Link>
                  </li> */}
                  <li className="animate__animated animate__fadeInDown animate__slower">
                    <Link
                      to="/contact"
                      onClick={closeNav}
                      className={`${
                        location.pathname === "/contact" ? "active" : ""
                      } 
              ${isNavOpen ? "is-opened" : ""}
            `}
                    >
                      <span className="menu-item">Contact</span>
                    </Link>
                  </li>
                </ol>
              </div>
              <Switch />
            </>
          ) : (
            <>
              <Switch />
              <button className="button-menu" onClick={handleNav}>
                <span className="bars">
                  <span className="bar1"></span>
                  <span className="bar2"></span>
                  <span className="bar3"></span>
                </span>
                <span className="btn-menu-title">MENU</span>
              </button>
            </>
          )}
        </div>
      </header>
      {/* Menu Expended */}
      <div className={`menu-wrapper ${isNavOpen ? "menu-visible" : ""}`}>
        {/* Menu Btn */}
        <button className="button-menu" onClick={handleNav}>
          <span className="bars">
            <span className="bar1"></span>
            <span className="bar2"></span>
            <span className="bar3"></span>
          </span>
          <span className="btn-menu-title">MENU</span>
        </button>
        <nav className="navbar">
          {/* <Link
            to="/"
            onClick={closeNav}
            className={`${location.pathname === "/" ? "active" : ""} ${
              isNavOpen ? "is-opened" : ""
            }`}
          >
            <span className="menu-item">Home</span>
          </Link> */}
          <Link
            to="/about"
            onClick={closeNav}
            className={`${
              location.pathname === "/about" ? "active" : ""
            } ${isNavOpen ? "is-opened" : ""}`}
          >
            <span className="menu-item">About</span>
          </Link>
          {/* <Link
            to="/work"
            onClick={closeNav}
            className={`${location.pathname === "/work" ? "active" : ""} 
              ${isNavOpen ? "is-opened" : ""}`}
          >
            <span className="menu-item">Work</span>
          </Link> */}
          <Link
            to="/experience"
            onClick={closeNav}
            className={`${
              location.pathname === "/experience" ? "active" : ""
            } 
              ${isNavOpen ? "is-opened" : ""}
            `}
          >
            <span className="menu-item">Experience</span>
          </Link>
          <Link
            to="/contact"
            onClick={closeNav}
            className={`${
              location.pathname === "/contact" ? "active" : ""
            } 
              ${isNavOpen ? "is-opened" : ""}
            `}
          >
            <span className="menu-item">Contact</span>
          </Link>
        </nav>
        <div className="">
          <div className="mobile-social-menu">
            <SocialBar />
          </div>
          <a
            href="mailto:hello@aftabkhan.net"
            data-email="hello@aftabkhan.net"
            className="menu-email email"
          ></a>
        </div>
      </div>
      <SocialBar />
      <div
        className={`overlay ${isNavOpen ? "show" : "hide"}`}
        onClick={closeNav}
      ></div>
    </>
  );
}