import './Switch.css';
import MoonIcon from '../../Icons/MoonIcon';
import SunIcon from '../../Icons/SunIcon';

export default function Switch() {
  const setDarkMode =() => {
    document.querySelector("body").setAttribute("data-theme", "dark")
    localStorage.setItem("selectedTheme", "dark");
  };
  const setliteMode = () => {
    document.querySelector("body").setAttribute("data-theme", "light");
    localStorage.setItem("selectedTheme", "light");
  };
  

  const selectedTheme = localStorage.getItem("selectedTheme");

  if (selectedTheme === "dark") setDarkMode();
  else setliteMode();  

  const toggleTheme = (e) => {
    if(e.target.checked) setDarkMode();
    else setliteMode();
  };

  // const ToggleCSS = () => {
  //   document.body.classList.toggle('app-light')
  // }   
    return (
      <div
        className="css-switch"
        data-ui="dark"
        data-ui-dir="https://trueman.bslthemes.com/wp-content/themes/trueman"
      >
        <div className="css-switcher">
          <input
            className="tgl tgl-ios"
            id="tgl-swich"
            type="checkbox"
            defaultChecked={selectedTheme === "dark"}
            onChange={toggleTheme}
          />
          <label className="tgl-btn" htmlFor="tgl-swich">
            <SunIcon />
            <MoonIcon />
          </label>
        </div>
      </div>
    );
};
