/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import PageHelmet from "../../components/Helmet/Helmet";
import { Link } from "react-router-dom";
import './Home.css';
import Resume from '../../docs/Aftab_Khan__Resume.pdf';

const calculateTotalExperience = (experiences) => {
  let totalExperience = 0;

  experiences.forEach(({ startDate, endDate }) => {
    const startYear = new Date(startDate).getFullYear();
    const endYear = endDate
      ? new Date(endDate).getFullYear()
      : new Date().getFullYear();
    const experience = endYear - startYear;

    totalExperience += Math.max(experience, 0);
  });

  return totalExperience;
};

export default function Home() {

  const experiences = [
    { startDate: "01-09-2007", endDate: "2010-02-27" },
    { startDate: "01-09-2011", endDate: "2011-12-03" },
    { startDate: "2011-12-25", endDate: "2014-09-30" },
    { startDate: "01-10-2014", endDate: "2015-02-28" },
    { startDate: "01-03-2015", endDate: "2015-09-30" },
    { startDate: "01-03-2016", endDate: "2023-11-30" },
    { startDate: "01-12-2013", endDate: new Date().getFullYear() },
    // Add more experiences as needed
  ];

  const totalExperience = calculateTotalExperience(experiences);

  return (
    <>
      <PageHelmet
        PageTitle="Aftab Khan - Front-end Developer"
        CanonicalLink="https://aftabkhan.net/"
        description="Welcome to Aftab Khan's website. Explore the journey of a skilled Front-End Developer with a passion for creating exceptional user interfaces. Discover my skills, experience, and commitment to crafting outstanding web experiences."
        keywords="Front-End Developer, UI Designer, Web Development, User Interfaces, Creative Coding, Aftab Khan, Portfolio Intro."
      />
      <section className="app-content-area" id="home-page">
        <div className="container">
          <div className="row">
            <div className="home-hero">
              <h1>Hi, my name is</h1>
              <h2 className="animate__animated animate__fadeInDown">
                Aftab Khan
              </h2>
              <h3 className="animate__animated animate__fadeInDown animate__delay-1s">
                I build things for the web.
              </h3>
              <p className="animate__animated animate__fadeInDown animate__delay-2s">
                I'm a Front End Developer specializing in building (and
                occasionally designing) exceptional digital experiences.
                Currently, I'm focused on building accessible, human-centered
                products at{" "}
                <a
                  href="https://www.sagarsoft.in/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Sagarsoft
                </a>{" "}
                (India) Ltd.
              </p>

              <div className="d-flex flex-wrap home-link gap-3">
                <Link
                  to="/contact"
                  className="btn-effect btn-1 animate__animated animate__fadeInDown
                    animate__delay-3s"
                >
                  Get in touch
                </Link>
                <a
                  href={Resume}
                  target="_blank"
                  className="btn-effect btn-4 animate__animated animate__fadeInDown
                    animate__delay-4s"
                  rel="noreferrer"
                >
                  Resume
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Number */}
      <div className="experiance-number experiance-circle-text mrg-right">
        <div className="num experiance-text-white">
          <span>{`${totalExperience}+`}</span>
        </div>
        <div className="label experiance-text-black experiance-circle-text-label">
          <span style={{ transform: "rotate(0deg)" }}> </span>
          <span style={{ transform: "rotate(8.5deg)" }}>Y</span>
          <span style={{ transform: "rotate(17deg)" }}>e</span>
          <span style={{ transform: "rotate(25.5deg)" }}>a</span>
          <span style={{ transform: "rotate(34deg)" }}>r</span>
          <span style={{ transform: "rotate(42.5deg)" }}>s</span>
          <span style={{ transform: "rotate(51deg)" }}> </span>
          <span style={{ transform: "rotate(59.5deg)" }}>o</span>
          <span style={{ transform: "rotate(68deg)" }}>f</span>
          <span style={{ transform: "rotate(76.5deg)" }}> </span>
          <span style={{ transform: "rotate(85deg)" }}>D</span>
          <span style={{ transform: "rotate(93.5deg)" }}>i</span>
          <span style={{ transform: "rotate(102deg)" }}>g</span>
          <span style={{ transform: "rotate(110.5deg)" }}>i</span>
          <span style={{ transform: "rotate(119deg)" }}>t</span>
          <span style={{ transform: "rotate(127.5deg)" }}>a</span>
          <span style={{ transform: "rotate(136deg)" }}>l</span>
          <span style={{ transform: "rotate(144.5deg)" }}> </span>
          <span style={{ transform: "rotate(153deg)" }}>S</span>
          <span style={{ transform: "rotate(161.5deg)" }}>o</span>
          <span style={{ transform: "rotate(170deg)" }}>l</span>
          <span style={{ transform: "rotate(178.5deg)" }}>u</span>
          <span style={{ transform: "rotate(187deg)" }}>t</span>
          <span style={{ transform: "rotate(195.5deg)" }}>i</span>
          <span style={{ transform: "rotate(204deg)" }}>o</span>
          <span style={{ transform: "rotate(212.5deg)" }}>n</span>
          <span style={{ transform: "rotate(221deg)" }}>s</span>
          <span style={{ transform: "rotate(229.5deg)" }}> </span>
          <span style={{ transform: "rotate(238deg)" }}>E</span>
          <span style={{ transform: "rotate(246.5deg)" }}>x</span>
          <span style={{ transform: "rotate(255deg)" }}>p</span>
          <span style={{ transform: "rotate(263.5deg)" }}>e</span>
          <span style={{ transform: "rotate(272deg)" }}>r</span>
          <span style={{ transform: "rotate(280.5deg)" }}>i</span>
          <span style={{ transform: "rotate(289deg)" }}>e</span>
          <span style={{ transform: "rotate(297.5deg)" }}>n</span>
          <span style={{ transform: "rotate(306deg)" }}>c</span>
          <span style={{ transform: "rotate(314.5deg)" }}>e</span>
          <span style={{ transform: "rotate(323deg)" }}> </span>
        </div>
      </div>

      {/* Schema Markup for Home Page */}
      <script type="application/ld+json">
        {`{
          "@context": "http://schema.org",
          "@type": "WebPage",
          "name": "Aftab Khan - Front-end Developer",
          "description": "Explore the journey of a skilled Front-End Developer with a passion for creating exceptional user interfaces.",
          "url": "https://aftabkhan.net",
          "mainEntityOfPage": {
            "@type": "WebSite",
            "@id": "https://aftabkhan.net"
          }
        }`}
      </script>
    </>
  );
}