import "./Preloader.css";

export default function Preloader() {
  return (
    <div className="preloader-wrap">
      <div className="preloader-holder">
        <div className="preloader">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
}
