import React from "react";
import PageHelmet from "../../components/Helmet/Helmet";
import './About.css';
import AftabKhan from "../../imgs/profile-picture.webp";
import ReactDOM from "react-dom";
import Tilt from "react-parallax-tilt";

// Age Calculation
  function calculateAge(birthDate) {

    const today = new Date();
    const birthDateObj = new Date(birthDate);

    const yearsDiff = today.getFullYear() - birthDateObj.getFullYear();
    
    if (
      today.getMonth() < birthDateObj.getMonth() ||
      (today.getMonth() === birthDateObj.getMonth() &&
        today.getDate() < birthDateObj.getDate())
    ) {
      return yearsDiff - 1; // Subtract 1 year if the birth date hasn't occurred yet this year
    } else {
      return yearsDiff;
    }
  }

const birthDate = "1985-09-27"; // Format: 'YYYY-MM-DD'
const age = calculateAge(birthDate);
//console.log(`Age: ${age}`);

export default function About(props)  { 
    return (
      <>
        <PageHelmet
          PageTitle="Aftab Khan - About Me"
          CanonicalLink="https://aftabkhan.net/about"
          description="Learn about Aftab Khan, a highly skilled Front-End Developer with over a decade of expertise. Explore my journey, skills, and dedication to designing and implementing user interfaces for web applications."
          keywords="Aftab Khan Bio, Front-End Developer Journey, UI/UX Design, Coding Expertise, Web Development Passion."
        />
        <section className="app-content-area" id="about-page">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="page-heading animate__animated animate__fadeInDown animate__slow">
                  About Me
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <p className="animate__animated animate__fadeIn animate__slow animate__delay-1s">
                  Greetings!
                </p>
                <p className="animate__animated animate__fadeIn animate__slow animate__delay-2s">
                  I'm Aftab ({age}), and I derive joy from crafting content for
                  the online realm. My journey into web development began in
                  2012, sparked by my experimentation with customizing WordPress
                  themes. Interestingly, my exploration of creating a bespoke
                  reblog button evolved into an enlightening journey through the
                  realms of HTML, CSS, and JavaScript.
                </p>
                <p className="animate__animated animate__fadeIn animate__slow animate__delay-3s">
                  Fast-forward to the present, I've had the fortune of
                  contributing my skills to diverse environments, including an{" "}
                  <strong>advertising agency</strong>, <strong>startups</strong>
                  , and <strong>large corporations</strong>. Currently, my
                  primary focus revolves around constructing accessible and
                  inclusive digital products and experiences for a range of
                  clients.
                </p>
                <p className="animate__animated animate__fadeIn animate__slow animate__delay-4s">
                  Here are some of the technologies I've been actively engaged
                  with lately:
                </p>
                <ul className="skills-list animate__animated animate__fadeIn animate__slow animate__delay-5s">
                  <li>HTML 5</li>
                  <li>CSS 3</li>
                  <li>JavaScript (ES6)</li>
                  <li>React JS</li>
                  <li>Node.js</li>
                  <li>Bootstrap</li>
                  <li>Photoshop</li>
                  <li>WordPress</li>
                </ul>
              </div>
              <div className="col-lg-6 position-relative">
                <div className="profile-picture column">
                  <div>
                    <Tilt
                      className="parallax-effect-img"
                      tiltMaxAngleX={20}
                      tiltMaxAngleY={20}
                      perspective={1500}
                      transitionSpeed={1500}
                      scale={1.02}
                      gyroscope={true}
                    >
                      <figure className="animate__animated animate__bounceInRight animate__slow">
                        <img
                          src={AftabKhan}
                          alt="Aftab Khan - Front end developer"
                          className="img-fluid inner-element"
                        />
                      </figure>
                      <span className="animate__animated animate__fadeIn animate__slow animate__delay-1s"></span>
                    </Tilt>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Schema Markup for About Me Page */}
        <script type="application/ld+json">
          {`{
          "@context": "http://schema.org",
          "@type": "AboutPage",
          "name": "Aftab Khan - About Me",
          "description": "Learn about Aftab Khan, a highly skilled Front-End Developer with over a decade of expertise.",
          "url": "https://aftabkhan.net/about",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://aftabkhan.net/about"
          }
        }`}
        </script>
      </>
    );
}