export default function ChatIcon() {
  return (
    <svg
      fill="var(--primary-color)"
      version="1.1"
      id="XMLID_276_"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      width="80px"
      height="80px"
      stroke="var(--primary-color)"
      className="mb-2"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth={0} />
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g id="SVGRepo_iconCarrier">
        {" "}
        <g id="contact-us">
          {" "}
          <g>
            {" "}
            <path d="M4,24v-5H0V0h23v19h-9.3L4,24z M2,17h4v3.7l7.3-3.7H21V2H2V17z" />{" "}
          </g>{" "}
          <g>
            {" "}
            <rect x={5} y={8} width={3} height={3} />{" "}
          </g>{" "}
          <g>
            {" "}
            <rect x={10} y={8} width={3} height={3} />{" "}
          </g>{" "}
          <g>
            {" "}
            <rect x={15} y={8} width={3} height={3} />{" "}
          </g>{" "}
        </g>{" "}
      </g>
    </svg>
  );
}
