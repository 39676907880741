import React, { useState, useEffect } from "react";
import PageHelmet from "../../components/Helmet/Helmet";
import "./Experience.css";

const expCurrent = () => (
  <div className="experience-details overflow-hidden">
    <h3 className="animate__animated animate__fadeInDown">
      <span>Senior Software Engineer</span>
      <span className="company">
        {" "}
        @{" "}
        <a
          href="https://www.sagarsoft.in/"
          className="inline-link"
          rel="noopener noreferrer"
          target="_blank"
        >
          Sagarsoft (India) Limited
        </a>
      </span>
    </h3>
    <p className="range animate__animated animate__fadeInDown">
      Dec 2023 - Present
    </p>
    <div className="description animate__animated animate__fadeIn animate__slower">
      <ul>
        <li>
          Enhanced ongoing Walmart project for robustness and reliability.
        </li>
        <li>
          Applied Jest for comprehensive code testing and advanced debugging.
        </li>
        <li>
          Conducted precise code reviews, providing constructive feedback.
        </li>
        <li>
          Implemented eff ective testing strategies for optimized performance.
        </li>
        <li>
          Orchestrated source code management with Git for collaborative
          development.
        </li>
      </ul>
    </div>

    <div className="custom-table animate__animated animate__fadeIn animate__slower">
      <div className="table-row header">
        <div className="table-cell">Projects</div>
        <div className="table-cell">Duration</div>
        <div className="table-cell">Skills</div>
      </div>
      <div className="table-row">
        <div className="table-cell" data-title="Name">
          Sentrifugo (Timesheet Module)
        </div>
        <div className="table-cell" data-title="Age">
          Feb 2024 - Present
        </div>
        <div className="table-cell" data-title="Occupation">
          React JS, HTML, CSS, Git
        </div>
      </div>
      <div className="table-row">
        <div className="table-cell" data-title="Name">
          Walmart (Dispatcher Module)
        </div>
        <div className="table-cell" data-title="Age">
          2 Months
        </div>
        <div className="table-cell" data-title="Occupation">
          React JS, HTML, CSS, JIRA, Git, Figma, ESLint, Prettier, Agile
        </div>
      </div>
    </div>
  </div>
);

const expSixth = () => (
  <div className="experience-details overflow-hidden">
    <h3 className="animate__animated animate__fadeInDown">
      <span>Senior Software Engineer - UI</span>
      <span className="company">
        {" "}
        @{" "}
        <a
          href="https://www.sapplica.com/"
          className="inline-link"
          rel="noopener noreferrer"
          target="_blank"
        >
          Sapplica
        </a>
      </span>
    </h3>
    <p className="range animate__animated animate__fadeInDown">
      Mar 2016 - Nov 2023
    </p>
    <div className="description animate__animated animate__fadeIn animate__slower">
      <ul>
        <li>
          Contributions to Walmart and Sentrifugo projects using React.js,
          JavaScript, HTML, CSS, Material-UI, and Figma.
        </li>
        <li>
          Spearheaded user-friendly web app development with HTML, CSS, and
          JavaScript.
        </li>
        <li> Ensured code reliability through meticulous Jest testing.</li>
        <li> Utilized Veeva CRM/AE for specialized CLM App Development.</li>
        <li>
          Employed AngularJS for streamlined front-end development in specific
          projects.
        </li>
        <li>
          Demonstrated mastery in debugging, testing, and ensuring application
          stability.
        </li>
        <li>
          Coordinated source code management with Git for collaborative
          development.
        </li>
        <li> Internally transferred to Sagarsoft - Dec 2023</li>
      </ul>
    </div>
    <div className="custom-table animate__animated animate__fadeIn animate__slower">
      <div className="table-row header">
        <div className="table-cell">Projects</div>
        <div className="table-cell">Duration</div>
        <div className="table-cell">Skills</div>
      </div>
      <div className="table-row">
        <div className="table-cell" data-title="Name">
          Walmart (Dispatcher Module)
        </div>
        <div className="table-cell" data-title="Age">
          8 Months
        </div>
        <div className="table-cell" data-title="Occupation">
          React JS, HTML, CSS, JIRA, Git, Figma, ESLint, Prettier, Agile
        </div>
      </div>
      <div className="table-row">
        <div className="table-cell" data-title="Name">
          Sentrifugo (Timesheet Module)
        </div>
        <div className="table-cell" data-title="Age">
          1 Month
        </div>
        <div className="table-cell" data-title="Occupation">
          React JS, HTML, CSS, Git, Peer Code Review, Testing
        </div>
      </div>
      <div className="table-row">
        <div className="table-cell" data-title="Name">
          PFV (CLM, AE, Word to PDF)
        </div>
        <div className="table-cell" data-title="Age">
          4.5 Years
        </div>
        <div className="table-cell" data-title="Occupation">
          Veeva CRM, HTML, CSS, JavaScript, Salesforce, Git, Peer Code Review
        </div>
      </div>
      <div className="table-row">
        <div className="table-cell" data-title="Name">
          Helios Insurance (UI Support)
        </div>
        <div className="table-cell" data-title="Age">
          6 Months
        </div>
        <div className="table-cell" data-title="Occupation">
          Angular JS, HTML, CSS, JavaScript, Git, Bootstrap, Photoshop
        </div>
      </div>
      <div className="table-row">
        <div className="table-cell" data-title="Name">
          Virtual Agro Services
        </div>
        <div className="table-cell" data-title="Age">
          8 Months
        </div>
        <div className="table-cell" data-title="Occupation">
          HTML, CSS, JavaScript, Bootstrap, Photoshop
        </div>
      </div>
      <div className="table-row">
        <div className="table-cell" data-title="Name">
          PFV (CLM, AE)
        </div>
        <div className="table-cell" data-title="Age">
          5 Months
        </div>
        <div className="table-cell" data-title="Occupation">
          Veeva CRM, HTML, CSS, JavaScript, Salesforce, Git, Peer Code Review
        </div>
      </div>
      <div className="table-row">
        <div className="table-cell" data-title="Name">
          Sportsjun (UI Support)
        </div>
        <div className="table-cell" data-title="Age">
          2 Year
        </div>
        <div className="table-cell" data-title="Occupation">
          React JS, HTML, CSS, JavaScript, Git, Bootstrap, Photoshop
        </div>
      </div>
    </div>
  </div>
);

const expFifth = () => (
  <div className="experience-details overflow-hidden">
    <h3 className="animate__animated animate__fadeInDown">
      <span>Senior Front-end Developer</span>
      <span className="company">
        {" "}
        @{" "}
        <a
          href="https://www.newrepublique.com/"
          className="inline-link"
          rel="noopener noreferrer"
          target="_blank"
        >
          Sartora Digitals (New Republique Australia)
        </a>
      </span>
    </h3>
    <p className="range animate__animated animate__fadeInDown">
      Mar 2015 - Sep 2015
    </p>
    <div className="description animate__animated animate__fadeIn animate__slower">
      <ul>
        <li>
          Translated wireframes and mockups into interactive and visually
          appealing user interfaces.
        </li>
        <li>
          Ensured cross-browser and cross-platform compatibility of web
          applications.
        </li>
        <li>
          Implemented responsive designs optimized for various devices and
          screen sizes.
        </li>
        <li>
          Enhanced user experience through smooth animations, transitions, and
          intuitive interactions.
        </li>
      </ul>
    </div>

    <div className="custom-table animate__animated animate__fadeIn animate__slower">
      <div className="table-row header">
        <div className="table-cell">Projects</div>
        <div className="table-cell">Duration</div>
        <div className="table-cell">URL</div>
      </div>
      <div className="table-row">
        <div className="table-cell" data-title="Name">
          Ivory Group
        </div>
        <div className="table-cell" data-title="Age">
          30 Days
        </div>
        <div className="table-cell" data-title="Occupation">
          http://www.ivorygroup.com.au
        </div>
      </div>
      <div className="table-row">
        <div className="table-cell" data-title="Name">
          Employsure
        </div>
        <div className="table-cell" data-title="Age">
          30 Days
        </div>
        <div className="table-cell" data-title="Occupation">
          http://www.employsure.com.au
        </div>
      </div>
      <div className="table-row">
        <div className="table-cell" data-title="Name">
          Macquarie University
        </div>
        <div className="table-cell" data-title="Age">
          45 Days
        </div>
        <div className="table-cell" data-title="Occupation">
          http://openday.mq.edu.au
        </div>
      </div>
      <div className="table-row">
        <div className="table-cell" data-title="Name">
          Trippas White Group
        </div>
        <div className="table-cell" data-title="Age">
          60 Days
        </div>
        <div className="table-cell" data-title="Occupation">
          https://trippaswhitegroup.com.au
        </div>
      </div>
      <div className="table-row">
        <div className="table-cell" data-title="Name">
          Walsh Bay Kitchen
        </div>
        <div className="table-cell" data-title="Age">
          15 Days
        </div>
        <div className="table-cell" data-title="Occupation">
          http://www.walshbaykitchen.com.au
        </div>
      </div>
      <div className="table-row">
        <div className="table-cell" data-title="Name">
          Avalon on the Beach
        </div>
        <div className="table-cell" data-title="Age">
          15 Days
        </div>
        <div className="table-cell" data-title="Occupation">
          http://www.theavalononthebeach.com
        </div>
      </div>
      <div className="table-row">
        <div className="table-cell" data-title="Name">
          Dove Men Care Australia
        </div>
        <div className="table-cell" data-title="Age">
          10 Days
        </div>
        <div className="table-cell" data-title="Occupation">
          http://www.dovemencare.com.au
        </div>
      </div>
    </div>
    <p className="skills animate__animated animate__fadeIn animate__slower">
      Skills - HTML, CSS, Bootstrap, Foundation CSS, Less/Scss, JavaScript
    </p>
  </div>
);

// const expFourth = () => (
//   <div className="experience-details overflow-hidden">
//     <h3 className="animate__animated animate__fadeInDown">
//       <span>Senior Designer</span>
//       <span className="company">
//         {" "}
//         @{" "}
//         <a
//           href="https://www.ramojifilmcity.com/"
//           className="inline-link"
//           rel="noopener noreferrer"
//           target="_blank"
//         >
//           Ramoji Film Center
//         </a>
//       </span>
//     </h3>
//     <p className="range animate__animated animate__fadeInDown">
//       Oct 2014 - Feb 2015
//     </p>
//     <div className="description animate__animated animate__fadeIn animate__slower">
//       <ul>
//         <li>
//           Creating visually appealing designs for various mediums, including
//           digital interfaces, print materials, or marketing collateral.
//         </li>
//         <li>
//           Developing and refining design concepts, incorporating feedback and
//           iterating on designs.
//         </li>
//         <li>
//           Selecting appropriate color palettes, typography, and visual elements
//           to enhance the overall aesthetics of the design.
//         </li>
//         <li>
//           Ensuring consistency and adherence to brand guidelines across all
//           design deliverables.
//         </li>
//         <li>
//           Collaborating with cross-functional teams, including developers,
//           marketers, and project managers, to align design deliverables with
//           project goals and timelines.
//         </li>
//       </ul>
//     </div>
//   </div>
// );

const expThird = () => (
  <div className="experience-details overflow-hidden">
    <h3 className="animate__animated animate__fadeInDown">
      <span>Senior Web Designer</span>
      <span className="company">
        {" "}
        @{" "}
        <a
          href="https://www.inethostings.com/"
          className="inline-link"
          rel="noopener noreferrer"
          target="_blank"
        >
          iNet Hostings Solution
        </a>
      </span>
    </h3>
    <p className="range animate__animated animate__fadeInDown">
      Dec 2011 - Sep 2014
    </p>
    <div className="description animate__animated animate__fadeIn animate__slower">
      <ul>
        <li>
          Developed web apps with HTML, CSS, and JavaScript to optimize user
          experiences.
        </li>
        <li>
          Implemented Bootstrap for designing responsive and visually appealing
          interfaces.
        </li>
        <li>Expertise in debugging and testing for code reliability.</li>
        <li>
          Utilized Photoshop for precision in web design and image editing.
        </li>
        <li>Managed web hosting and upheld servers for seamless deployment.</li>
      </ul>
    </div>

    <div className="custom-table animate__animated animate__fadeIn animate__slower">
      <div className="table-row header">
        <div className="table-cell">Projects</div>
        <div className="table-cell">Duration</div>
        <div className="table-cell">URL</div>
      </div>
      <div className="table-row">
        <div className="table-cell" data-title="Name">
          Moda Angeli
        </div>
        <div className="table-cell" data-title="Age">
          1 Month
        </div>
        <div className="table-cell" data-title="Occupation">
          http://www.modaangeli.com
        </div>
      </div>
      <div className="table-row">
        <div className="table-cell" data-title="Name">
          Azhar Al Madina
        </div>
        <div className="table-cell" data-title="Age">
          1 Year
        </div>
        <div className="table-cell" data-title="Occupation">
          http://www.azharalmadina.ae
        </div>
      </div>
      <div className="table-row">
        <div className="table-cell" data-title="Name">
          Al Ahsan Consultants
        </div>
        <div className="table-cell" data-title="Age">
          5 Days
        </div>
        <div className="table-cell" data-title="Occupation">
          http://www.alahsanconsultants.com
        </div>
      </div>
      <div className="table-row">
        <div className="table-cell" data-title="Name">
          Rabit Search
        </div>
        <div className="table-cell" data-title="Age">
          8 Months
        </div>
        <div className="table-cell" data-title="Occupation">
          http://www.rabitsearch.com
        </div>
      </div>
    </div>

    <p className="skills animate__animated animate__fadeIn animate__slower">
      Skills - HTML, CSS, Bootstrap, JavaScript, Photoshop, cPanel, Web Hosting
    </p>
  </div>
);

const expSecond = () => (
  <div className="experience-details overflow-hidden">
    <h3 className="animate__animated animate__fadeInDown">
      <span>Web Designer</span>
      <span className="company"> @ e-Thamarat</span>
    </h3>
    <p className="range animate__animated animate__fadeInDown">
      Sep 2011 - Dec 2011
    </p>
    <div className="description animate__animated animate__fadeIn animate__slower">
      <ul>
        <li>
          Contributed significantly to the Almoktashef.com.sa website, utilizing
          HTML, CSS, and JavaScript.
        </li>
        <li>
          Employed Bootstrap for developing responsive and visually appealing
          interfaces.
        </li>
        <li>
          Applied Photoshop for precision in web design and image editing.
        </li>
      </ul>
    </div>
    <div className="custom-table animate__animated animate__fadeIn animate__slower">
      <div className="table-row header">
        <div className="table-cell">Projects</div>
        <div className="table-cell">Duration</div>
        <div className="table-cell">URL</div>
      </div>
      <div className="table-row">
        <div className="table-cell" data-title="Name">
          Almoktashef
        </div>
        <div className="table-cell" data-title="Age">
          4 Months
        </div>
        <div className="table-cell" data-title="Occupation">
          http://www.almoktashef.com.sa
        </div>
      </div>
    </div>

    <p className="skills animate__animated animate__fadeIn animate__slower">
      Skills - HTML, CSS, Bootstrap, JavaScript, Photoshop
    </p>
  </div>
);

const expFirst = () => (
  <div className="experience-details overflow-hidden">
    <h3 className="animate__animated animate__fadeInDown">
      <span>Graphic Designer</span>
      <span className="company"> @ Media Concepts</span>
    </h3>
    <p className="range animate__animated animate__fadeInDown">
      Sep 2007 - Feb 2010
    </p>
    <div className="description animate__animated animate__fadeIn animate__slower">
      <ul>
        <li>
          Executed graphic design tasks using Photoshop, Illustrator, InDesign,
          Acrobat Reader, and CorelDraw.
        </li>
        <li>
          Demonstrated expertise in print solutions, ensuring high-quality
          output.
        </li>
      </ul>
    </div>
    <div className="custom-table animate__animated animate__fadeIn animate__slower">
      <div className="table-row header">
        <div className="table-cell">Projects</div>
        <div className="table-cell">Duration</div>
        <div className="table-cell">Description</div>
      </div>
      <div className="table-row">
        <div className="table-cell" data-title="Name">
          MPMKVVCL
        </div>
        <div className="table-cell" data-title="Age">
          1 Year
        </div>
        <div className="table-cell" data-title="Occupation">
          Designed Power Grid Map
        </div>
      </div>
      <div className="table-row">
        <div className="table-cell" data-title="Name">
          Pharma Products
        </div>
        <div className="table-cell" data-title="Age">
          2 Years
        </div>
        <div className="table-cell" data-title="Occupation">
          DMarketing Collateral
        </div>
      </div>
    </div>

    <p className="skills animate__animated animate__fadeIn animate__slower">
      Skills - Photoshop, Illustrator, CorelDRAW, Acrobat PDF, MS Office
    </p>
  </div>
);

export default function Experience() {
  const [isMobile, setIsMobile] = useState(window.innerWidth > 991);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth > 991);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const renderTabs = () => {
    const tabs = [
      "Sagarsoft",
      "Sapplica",
      "Sartora Digitals",
      // "Ramoji Film City",
      "iNet Hostings Solutions",
      "e-Thamarat",
      "Media Concepts",
    ];

    return tabs.map((tab, index) => (
      <div
        key={index}
        className={`tab ${
          index === activeTab ? "active" : ""
        } animate__animated animate__fadeInLeft animate__slow`}
        onClick={() => handleTabClick(index)}
      >
        {tab}
      </div>
    ));
  };

  const renderContent = () => {
    const contentComponents = [
      expCurrent,
      expSixth,
      expFifth,
      // expFourth,
      expThird,
      expSecond,
      expFirst,
    ];
    const SelectedContent = contentComponents[activeTab];

    return (
      <div className="tab-content animate__animated animate__fadeInRight animate__slow">
        <div className="card-background">
          <div className="hover-state-indicator"></div>
          <div className="card-wrapper">{<SelectedContent />}</div>
        </div>
      </div>
    );
  };

  const experienceComponents = [
    expCurrent(),
    expSixth(),
    expFifth(),
    // expFourth(),
    expThird(),
    expSecond(),
    expFirst(),
  ];

  const renderListOfExperienceComponents = experienceComponents.map((item) => (
    <li className="tab-contents mb-3" key={item}>
      <div className="card-background">
        <div className="hover-state-indicator"></div>
        <div className="card-wrapper">{item}</div>
      </div>
    </li>
  ));

  return (
    <>
      <PageHelmet
        PageTitle="Aftab Khan - Experience"
        CanonicalLink="https://aftabkhan.net/experience"
        description="Explore Aftab Khan's professional experience as a Senior Software Engineer - UI. Dive into web development projects, collaborations, and the mastery of HTML5, CSS3, JavaScript (ES6+), React JS, and more."
        keywords="Senior Software Engineer UI, Web Development Projects, HTML5, CSS3, JavaScript (ES6+), React JS, Collaboration, Veeva CLM."
      />
      <section className="app-content-area" id="experience-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <h2 className="page-heading animate__animated animate__fadeInDown animate__slow">
                I've Worked For
              </h2>
            </div>
          </div>
          {isMobile ? (
            <div className="row">
              <div className="col-lg-12">
                <div className="vertical-tabs">
                  <div className="tabs-container">{renderTabs()}</div>
                  {renderContent()}
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-12">
                <ul>{renderListOfExperienceComponents}</ul>
              </div>
            </div>
          )}
        </div>
      </section>

      {/* Schema Markup for Experience Page */}
      <script type="application/ld+json">
        {`{
          "@context": "http://schema.org",
          "@type": "WebPage",
          "name": "Aftab Khan - Experience",
          "description": "Explore Aftab Khan's professional experience as a Senior Software Engineer - UI. Dive into web development projects, collaborations, and mastery of HTML5, CSS3, JavaScript (ES6+), React JS, and more.",
          "url": "https://aftabkhan.net/experience",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://aftabkhan.net/experience"
          }
        }`}
      </script>
    </>
  );
}
