export default function LocationIcon() {
  return (
    <svg
      height="200px"
      width="200px"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      fill="#000000"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth={0} />
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          style={{ fill: "#E6F3FF" }}
          d="M427.459,512c-120.907,0-222.018,0-342.924,0c-9.234,0-16.706-7.472-16.706-16.706 c0-85.685,0-162.244,0-247.929c0-4.437,1.756-8.674,4.895-11.811c60.323-60.323,110.961-106.38,171.285-166.704 c3.263-3.263,7.672-4.895,11.945-4.895c4.273,0,8.416,1.632,11.678,4.895c60.584,60.584,111.056,106.839,171.639,167.422 c3.137,3.137,4.895,7.374,4.895,11.811c0,85.426,0,161.786,0,247.212C444.166,504.528,436.695,512,427.459,512z"
        />{" "}
        <path
          style={{ fill: "#CFDBE6" }}
          d="M267.633,68.848c-3.251-3.251-7.379-4.874-11.632-4.886V512h0.179h171.281 c9.234,0,16.706-7.472,16.706-16.706c0-85.426,0-161.786,0-247.212c0-4.437-1.756-8.675-4.895-11.811 C378.689,175.686,328.215,129.431,267.633,68.848z"
        />{" "}
        <path
          style={{ fill: "#1F63AD" }}
          d="M333.912,512H177.987V361.643c0-9.226,7.48-16.706,16.706-16.706h122.513 c9.226,0,16.706,7.48,16.706,16.706V512z"
        />{" "}
        <path
          style={{ fill: "#004999" }}
          d="M317.206,344.937h-61.206V512h77.913V361.643C333.912,352.416,326.432,344.937,317.206,344.937z"
        />{" "}
        <path
          style={{ fill: "#2EE6E6" }}
          d="M256.18,266.974c-27.637,0-50.119-22.482-50.119-50.119s22.482-50.119,50.119-50.119 s50.119,22.482,50.119,50.119S283.816,266.974,256.18,266.974z"
        />{" "}
        <path
          style={{ fill: "#26BFBF" }}
          d="M256.18,166.737c-0.061,0-0.118,0.018-0.179,0.018v100.201c0.061,0,0.118,0.018,0.179,0.018 c27.637,0,50.119-22.482,50.119-50.119S283.816,166.737,256.18,166.737z"
        />{" "}
        <path
          style={{ fill: "#FF4B26" }}
          d="M501.895,232.24C423.414,153.758,357.926,88.269,279.444,9.789C272.918,3.263,264.503,0,255.954,0 c-8.549,0-17.232,3.263-23.757,9.789C153.846,88.14,88.456,153.53,10.105,231.881c-13.02,13.02-13.02,34.228,0,47.248 c13.052,13.052,34.196,13.052,47.248,0c70.65-70.65,123.749-123.599,198.619-198.469c109.624,109.624,87.99,88.14,198.677,198.827 c13.059,13.059,34.189,13.059,47.248,0C514.915,266.468,514.915,245.26,501.895,232.24z"
        />{" "}
        <path
          style={{ fill: "#D93F21" }}
          d="M279.444,9.789c-6.513-6.513-14.915-9.769-23.445-9.781v80.68 c109.588,109.588,87.971,88.121,198.648,198.798c13.058,13.058,34.19,13.058,47.248,0c13.019-13.019,13.019-34.228,0-47.248 C423.413,153.758,357.927,88.269,279.444,9.789z"
        />{" "}
      </g>
    </svg>
  );
}
