import React from "react";
import { Link } from "react-router-dom";
import './NotFoundPage.css'

const NotFoundPage = () => {
  return (
    <section className="app-content-area">
      <div className="container" id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <h1>Uh-oh!</h1>
          </div>
          <h2>404 - Page not found</h2>
          <p>The page you requested doesn't seem to exist.</p>
          <Link
            to="/"
            className="btn-effect btn-1 animate__animated animate__fadeInDown animate__delay-4s"
          >
            Return to Homepage
          </Link>
        </div>
      </div>
    </section>
  );
};

export default NotFoundPage;
