import React, { useEffect, useState } from "react";
import { useLocation, Route, Routes } from "react-router-dom";
// import ParticlesComponent from "./components/TsParticles/ParticlesComponent";
import AnimatedCursor from "react-animated-cursor";
import Preloader from "./components/Preloader/Preloader";
import Header from "./components/Header/Header";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
// import Work from "./pages/Work/Work";
import Experience from "./pages/Experience/Experience";
import Contact from "./pages/Contact/Contact";
import NotFoundPage from "./pages/Error/NotFoundPage";
import "./App-Fonts.css";
import "./App.css";
import "./App-Light.css";
import "animate.css";

export default function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth > 991);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth > 991);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Add dependencies if needed

  const location = useLocation();

  const [showPreloader, setShowPreloader] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowPreloader(false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      {/* {location.pathname === "/" ? (
        <ParticlesComponent id="tsparticles" />
      ) : null} */}
      <Preloader />
      <div className="animated-cursor">
        <AnimatedCursor
          color="0, 0, 0"
          innerSize={8}
          outerSize={30}
          innerScale={1}
          outerScale={2}
          outerAlpha={0}
          showSystemCursor={true}
          hasBlendMode={true}
          outerStyle={{
            border: "2px solid #000 ",
          }}
          innerStyle={{
            backgroundColor: "#000",
          }}
          clickables={[
            "a",
            'input[type="text"]',
            'input[type="email"]',
            'input[type="number"]',
            'input[type="submit"]',
            'input[type="image"]',
            "label[for]",
            "select",
            "textarea",
            "button",
            ".link",
          ]}
        />
      </div>
      <div
        className={`app-frame${showPreloader ? " is-animating" : ""}${
          location.pathname === "/"
            ? " home-page"
            : location.pathname === "/about"
            ? " about-page"
            : location.pathname === "/experience"
            ? " experience-page"
            : // : location.pathname === "/work"
            // ? " work-page"
            location.pathname === "/contact"
            ? " contact-page"
            : ""
        }`}
      >
        {/* <ParticlesComponent id="tsparticles" /> */}
        <Header mobileView={isMobile} />
        <div className="app-wrap">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            {/* <Route path="/work" element={<Work />} /> */}
            <Route
              path="/experience"
              element={<Experience />}
              mobileView={isMobile}
            />
            <Route path="/contact" element={<Contact />} />
            <Route path="/*" element={<NotFoundPage />} />
          </Routes>
        </div>
      </div>

      <script type="application/ld+json">
        {`{
          "@context": "http://schema.org",
          "@type": "Person",
          "name": "Aftab Khan",
          "url": "https://aftabkhan.net",
          "sameAs": [
            "https://www.linkedin.com/in/aftab-khan/",
            "https://github.com/aftabkhan",
            "https://twitter.com/itsme_aftab"
          ],
          "jobTitle": "Front-End Developer",
          "description": "Passionate front-end developer with a focus on creating visually appealing and user-friendly web experiences. Explore my portfolio to see my skills and projects.",
          "image": "https://aftabkhan.net/profile-picture.jpg",
          "mainEntityOfPage": {
            "@type": "WebSite",
            "@id": "https://aftabkhan.net"
          },
          "url": "https://aftabkhan.net",
          "sameAs": [
            "https://www.linkedin.com/in/aftab-khan/",
            "https://github.com/aftabkhan",
            "https://twitter.com/itsme_aftab"
          ],
          "homeLocation": {
            "@type": "Place",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Hyderabad",
              "addressRegion": "Telangana",
              "addressCountry": "India"
            }
          },
          "worksFor": {
            "@type": "Organization",
            "name": "Sagarsoft (India) Ltd."
          },
          "memberOf": [
            {
              "@type": "WebSite",
              "@id": "https://aftabkhan.net"
            }
          ]
        }`}
      </script>
    </>
  );
}
