import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import './SocialBar.css';

export default function SocialBar() {
    return (
      <>
        {/* Social Icons */}
        <div className="social-bar">
          <ul>
            <li className="animate__animated animate__fadeInUp animate__faster">
              <a href="https://twitter.com/TechMindAftab" target="_blank">
                <FontAwesomeIcon icon={faXTwitter} />
                <i className="ti-twitter-alt" />
              </a>
            </li>
            <li className="animate__animated animate__fadeInUp animate__fast">
              <a href="https://github.com/aftabkhan" target="_blank">
                <FontAwesomeIcon icon={faGithub} />
              </a>
            </li>
            <li className="animate__animated animate__fadeInUp animate__slow">
              <a href="https://www.linkedin.com/in/aftab-khan/" target="_blank">
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
            </li>
          </ul>
          <p>Follow Me</p>
        </div>
      </>
    );
}